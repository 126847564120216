.map{
    width: 40%;
    height: 75vh;
    position: relative;
    margin-left:1rem;
    overflow: hidden;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.637), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.map .mappin{
    position: absolute;
    bottom: 50%;
    left: 50%;
    color: rgb(38, 195, 51);
    transform: translateX(-50%);
    z-index: 1;
    height: 32px;
    width: 32px;
    
}

.leaflet-container{
    width: 100%;
    height: 100%;
    z-index: 0;
}


@media only screen and (max-width: 600px){
    .map{
        width: 90%;
        height: 20vh;
        position: relative;
        margin-left:0rem;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.637), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    
}