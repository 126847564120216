.pickupcard{
    width: 80%;
    text-align: left;
    background-color: rgb(59, 59, 59);
    color: white;
    border-radius: 4px;
    overflow: hidden;
}

.pickupcard > .details0{
    background-color: rgb(35, 222, 69);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
    color: black;
}

.pickupcard > .details1{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding: 1rem;
}

.pickupcard > .details2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.pickupcard h5{
    margin:0px
}

.pickupcard button{
    background-color: rgb(252, 208, 197);
    border: 0px;
    border-radius: 0.2rem;
    padding: 0.4rem;
    border-radius: 2px;
}

.pickupcard button:hover{
    background-color: rgb(223, 152, 135);
}

.pickupcard button:active{
    background-color: rgb(209, 113, 88);
}

.pickupcard:last-child{
    margin-bottom: 20px;
}


@media only screen and (max-width: 600px){
    .pickupcard > .details0{
        padding: 0.5rem;
        flex-direction: column;
        font-size: 0.9rem;
        font-weight: 600;
    }

    .pickupcard > .details1{
        margin-bottom: 1rem;
        margin-top: 1rem;
        flex-direction: column;
        gap:3px
    }
}