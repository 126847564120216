.App {
  text-align: center;
}


.outerwindow{
  height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:5%
}


.outerbox{
  width: 40%;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.outerbox button{
  background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    border: none;
    color: white;
    margin:3px;
    width: 150px;
}

.outerbox button:hover{
  background-color: rgb(0, 73, 49)
}

.outerbox button:active{
  background-color: rgb(1, 121, 81);
}

.App .loading{
  height: 100vh;
  width: 100%;
  position: absolute;
  top:0px;
  background-color: rgba(0, 0, 0, 0.522);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.App .loading div{
  height: 30px;
  width: 30px;
  border-radius: 50%;
  animation: loadinganime 1s infinite linear;
  border: solid 2px rgba(241, 241, 241, 0.708);;
}

@keyframes loadinganime {
  0%{
    border: none;
  }
  25%{
    border-top: solid 4px rgba(0, 255, 136, 0.708);
  }
  50%{
    border-right: solid 4px rgba(0, 255, 136, 0.708);
  }
  75%{
    border-bottom: solid 4px rgba(0, 255, 136, 0.708);
  }
  100%{
    border: solid 4px rgba(0, 255, 136, 0.708);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
