.home{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    
}

.pages{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:2rem

}

.page{
    height: 100vh;
    width: 100%;
    font-size: larger;
    text-align: left;
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2rem;
    gap:3rem;
    box-sizing: border-box;
}

.pages .process{
    flex-direction: column;
    gap:6rem;
    text-align: center;
    
}

.pages .process span{
    font-size: 40px;
}

.pages .recycleimg{
    background: url("./recycle.jpg") no-repeat center;
    height: 60%;
    aspect-ratio: 1/1;
    background-size: contain;
    mix-blend-mode:multiply;

}

.pages .processflowchart{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
    justify-content: center;
    align-items: center;
    
}


.pages .concern{
    display: flex;
    justify-content: center;
    flex-direction: column;
}



.position .green{
    background-color: green;
}

.processbox{
    width:27%;
    aspect-ratio: 1/1;
    overflow: hidden;
    text-align: center;
    position: relative;
    border-radius: 4px;
    margin: 1rem;
}


.processbox .scheduleimg{
    background: url("./schedule.jpg") no-repeat center;
    width: 100%;
    height: 100%;
    background-size: cover;
    top: 0px;
}

.processbox .measurmentimg{
    background: url("./measurement.png") no-repeat center;
    width: 100%;
    height: 100%;
    background-size: cover;
    top: 0px;
}

.processbox .returnsimg{
    background: url("./return.jpg") no-repeat center;
    width: 100%;
    height: 100%;
    background-size: cover;
    top: 0px;
}

.processbox .shadow{
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.658);
    top:0px
}

.processbox .description{
    position: absolute;
    transition: 500ms;
    top:65%;
    bottom: 0px;
    width: 100%;
    color: white;
    z-index: 1;
}

.processbox .description p{
    display: none;
}

.processbox:hover .description{
    text-align: center;
    color: white;
    height: 100%;
    top:0px
}

.processbox .description:hover p{
    display: contents;
}
.arrow{
    margin:5px;
}

.pages .condition{
    display: flex;
    flex-direction: column;
    align-items: unset;
}


@media only screen and (max-width: 600px){
    
    .page{
        
        flex-direction: column;
        font-size: medium;
        padding: 1rem;
        height: 82.5vh;
    }

        
    .pages .processflowchart{
       
        flex-direction: column;
        width: 100%;
        height: 80%;
    }

    .pages .process{
        flex-direction: column;
        gap:2rem;
        text-align: center;
        
    }

            
    .processbox{
        width:65%;
        height: auto;
        aspect-ratio: 1/1;
        font-size: 0.8rem;
    }

    .processbox .description {
        top:50%;
    }

    .pages .process span{
        font-size: medium;
    }



    .pages .recycleimg{
        width: 95%;
        height: auto;
        aspect-ratio: 1/1;

    }
    .arrow{
        transform: rotate(90deg);
    }

    
}