.pickupwindow{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    height: max-content;
}


.pickupestimate{
    margin-top: 5rem;
    padding-top: 1rem;
    top:64px;
    height: min-content;
    background: url();
}

.pickupestimate .material{
    background-color: rgb(127, 255, 157);
    font-weight: bold;
}


.pickupestimate .table{
    margin-bottom: 60px;
    display: flex;
    flex-wrap: wrap;
    gap:1rem;
    justify-content: center;
}

.pickupestimate button{
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    border: none;
    color: white;
    margin:3px;
    width: 150px;
    height: 40px;
    border-radius: 2px;
}


.pickupestimate button:disabled{
    background-color: rgb(90, 89, 89);
}


.pickupestimate button:disabled:hover{
    background-color: rgb(90, 89, 89);
}
.pickupestimate button:hover{
    background-color: rgb(0, 73, 49)
}

.pickupestimate button:active{
    background-color: rgb(1, 121, 81);
}

.pickupestimate .estimatedprice{
    margin:1rem
}

.pickupestimate .schedulemodule{
    display: flex;
    justify-content: space-evenly;
    margin-right: 1rem;
    align-items: center;
}

.pickupestimate .itemcard input{
    border-bottom: solid;
    width: 30%;
    height: 20px;
    margin:10px;
    outline: 0px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
}

.pickupestimate .schedulemodule input{
    border-bottom: solid;
    height: 20px;
    outline: 0px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
}

@media only screen and (max-width: 600px){

    .pickupwindow{
        display: flex;
        justify-content: center;
        
    }

    

    .pickupestimate{
        margin-bottom: 100px;
        top:64px;
        width: 95%;
       
    }
    
    .pickupestimate .schedulemodule{
        margin-right: 0rem;
        flex-direction: column;
    }

}