

.varify{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:5%
}
.varifymobile{
    width: 40%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(107, 236, 148);
    border: solid green;
    border-radius: 8px;
}

.varifymobile .countryselector{
    border: none;
    height:27px;
    border-bottom: solid;
}


.varifymobile input{
    border-bottom: solid;
    width: 50%;
    height: 20px;
    margin:10px;
    outline: 0px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
}
.varifymobile input:focus{
    border-bottom: solid rgb(0, 73, 49);
    
}
.varifymobile button{
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    border: none;
    color: white;
    margin:3px;
    width: 150px;
    border-radius: 2px;
}

.varifymobile button:disabled{
    background-color: rgb(90, 89, 89);
}


.varifymobile button:disabled:hover{
    background-color: rgb(90, 89, 89);
}
.varifymobile button:hover{
    background-color: rgb(0, 73, 49)
}

.varifymobile button:active{
    background-color: rgb(1, 121, 81);
}

@media only screen and (max-width: 600px){
        
    .varifymobile{
        width: 90%;
    }

}