.itemsswindow{
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    padding: 50px;
}

.itemsswindow .items{
    display: flex;
    flex-direction: row;
    gap:1rem;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 600px){

    .itemsswindow{
        padding-top: 100px;
        margin-left: auto;
        margin-right: auto;
    }
        

    .itemsswindow .items{
        display: flex;
        flex-direction: column;
        gap:1rem;
        justify-content: center;
        align-items: center;

    }

   
}