

.aboutus{
    background:rgba(0, 0, 0, 0.732);
    color:white;
    padding-left:2rem;
    padding-Right:2rem;
    padding-Bottom:3rem;
    border-Radius:1rem;
    }
@media only screen and (max-width: 600px){
    .aboutpage{
        padding-bottom: 120px;
    }
    .aboutus{
        margin-top: 130px;
    }
  
}