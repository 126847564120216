nav.kercollectornav{
    height: 70px;
    width: 100%;
    z-index: 2;
    position: fixed;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}
.kercollectornav > ul{
    display: flex;
    margin:0px;
    align-items: center;
    height: 70px;
    padding-right: 10%;
    padding-left: 5%;
}
.kercollectornav > ul > li{
    list-style-type: none;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 21px;
    padding-bottom: 21px;
}

.kercollectornav > ul > li:first-of-type{
    margin-left: 0;
}

.kercollectornav li > a{
    text-decoration: none;
    text-align: center;
    margin-bottom: auto;
    color: rgb(0, 0, 0);
    font-size: large;
    font-weight: 500;
}

.kercollectornav li > a:hover{
    color: rgb(15, 107, 62);
}

.kercollectornav li:hover > .userdropdowm{
    display: block;
}

.kercollectornav > ul > li:last-child{
    margin-right: 3rem;
}

.kercollectornav li.left{
    margin-left: auto;
    cursor: pointer;
}

.kercollectornav .showinformationlink{
    display: none;
}

.showinformationlinklabel{
    display: none;
}

.userdropdowm{
    position: absolute;
    top:64px;
    color: black;
    width: 150px;
    display: none;
    z-index: 1;
}

.userdropdowm > ul{
    padding:0px;
    list-style-type: none;

}

.userdropdowm > ul > li{
    padding :0.5rem;
    padding-top :0.6rem;
    padding-bottom: 0.6rem;
    background-color: rgb(235, 255, 239);
    
}

.userdropdowm > ul > li:last-child{
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
}

.userdropdowm > ul > li:hover{
    background-color: rgb(141, 248, 198);
}

.userdropdowm > ul > li:active{
    background-color: rgb(50, 243, 153);
}

.userdropdowminput{
    display: none;
}

.userdropdowm:hover{
    display: block;
}

.kercollectornav button{
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    border: none;
    color: white;
    border-radius: 2px;
}

.kercollectornav button:hover{
    background-color: rgb(0, 73, 49);
}

.kercollectornav button:active{
    background-color: rgb(1, 121, 81);   
}

.userdropdownname > .arrow {
    border: solid black;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    margin-left:2px;
  }
  .userdropdownname > .down {
      position: relative;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom:3px
  }
  .userdropdownname:hover > .arrow {
    border: solid rgb(29, 161, 98);
    border-width: 0 2px 2px 0;
  }
  

  
@media only screen and (max-width: 600px){
    nav.kercollectornav{
        width: 100%;
        z-index: 2;
        position: fixed;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-right: auto;
    }

    .kercollectornav > ul{
        display: flex;
        margin:0px;
        align-items: center;
        padding:0px;
    }

        
    .kercollectornav > ul > li{
        list-style-type: none;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        padding-top: 21px;
        padding-bottom: 21px;
        
    }

    .kercollectornav > ul > li:first-of-type{
        margin-left: 0.5rem;
    }

    .kercollectornav > ul > li.informationlink{
        display: none;
    }

    .kercollectornav > ul > li.left >a{
        font-size: 0.9rem;
    }
    .kercollectornav button{
        background-color: rgb(0, 0, 0);
        padding: 0.2rem;
        border: none;
        color: white;
        overflow:auto;
        width: 100px;
    }

        
    .showinformationlinklabel{
        display: flex;
        gap: 3px;
        flex-direction: column;
        margin-left: 0.3rem;
        margin-right: 2rem;
        padding :1px;
    }

    .showinformationlinklabel div{
        background-color: black;
        height:5px ;
        width: 20px;
    }

    .showinformationlinklabel div:first-of-type{
        background-color: black;
        height:5px ;
        width: 11px;
        left: 9px;
        position: relative;
    }

    .showinformationlink:not(:checked) ~ .informationlink{
        display: block;
        transition: 200ms;
        position:absolute;
        top: -100%;
        right: 10%;
    }

    .showinformationlink:checked ~ .informationlink{
        display: block;
        position:absolute;
        top: 100%;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.808);
        width: 120px;
        transition: 200ms;
    }

    .showinformationlink:checked ~ .informationlink > a{
        color: white;
    }
    .showinformationlink:checked ~ .informationlink:nth-of-type(2){
        left:120px;
        width: 140px;
    }
    .showinformationlink:checked ~ .informationlink:nth-of-type(3){
        left:260px;
        width: 90px;
    }

   

}