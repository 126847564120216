p{
    margin: 0px;
    margin-bottom:0.2rem;
}

.contactuscontent{
    color:black;
        display:flex;
        align-content:center;
        height:100vh;
        justify-content:center;
        gap:5rem
}

.contactform{
    display:flex;
    align-content:center;
    justify-content:center;
    flex-direction:column;
    text-align:left;
    gap:0.5rem
}

input.contactinput{
    height: 20px;
    background-color: rgba(255, 255, 255, 0.701);
}
@media only screen and (max-width: 600px){
    
.contactuscontent{
    padding-top: 100px;
    flex-direction: column;
    padding-bottom: 50px;
    padding-left: 3px;
    padding-right: 3px;
}
}
