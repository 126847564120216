.locationwindow{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    gap:2%;
}

.location{
    padding-bottom: 1.5%;
    width: 55%;
    text-align: left;
    
}

.location > form{
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
    box-sizing: border-box;
    border: solid green;
    border-radius: 8px;
    background-color: rgb(107, 236, 148);
}
.location > form div{
    display: inline-block;
    width: 50%;
}
.location input{
    border-bottom: solid;
    width: 90%;
    height: 30px;
    margin-bottom:10px;
    margin-top:5px;
    outline: 0px;
    border-top: 1px solid;
    border-right: 1px solid;
    border-left: 1px solid;
}

.location input.disabled{
    background-color: rgb(218, 216, 216);
    color: black;
}

.location input:focus{
    border-bottom: solid rgb(0, 228, 152);
    
}

.location button{
    background-color: rgb(0, 0, 0);
    padding: 0.5rem;
    border: none;
    color: white;
    margin:3px;
    margin-top:6px;
    border-radius: 2px;
}

.location button:hover{
    background-color: rgb(0, 73, 49)
}

.location button:active{
    background-color: rgb(1, 121, 81);   
}

.compulsory{
    color:red;
}

@media only screen and (max-width: 600px){
    .locationwindow{
        flex-direction: column;
        padding-top: 64px;
        gap:1rem;
        padding-bottom: 1rem;
    }

    .location > form div{
        display: block;
        width: 100%;
    }
        
    .location{
        width: 90%;
    }
        
    .location input{
        width: 85%;
    }
    
.location > form{
    padding-left: 10%;
}

}