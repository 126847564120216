.pickuporder{
    padding-top:100px;

}

.pickuporder .pickuparray{
    display: flex;
    flex-direction:column;
    gap: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.pickuporder h3{
    margin-bottom: 1rem;
}