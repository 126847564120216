.home .banner{
    text-align: left;
    height: 100vh;
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items:center;
    box-sizing: border-box;
    border-radius: 1rem;
    justify-content: space-between;
    margin-left: 5%;
}

.banner .image{
    background: url("junkpickup.jpg") no-repeat center;
    aspect-ratio: 16/16.45;
    width:45%;
    mix-blend-mode:multiply;
    background-size:cover;
    justify-self:end
}


.banner > .bannercontent{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner .staticstics{
    display: flex;
    flex-direction: row;
    gap: 8rem;
    justify-content:left;
}

.banner .staticstics .data{
    text-align: center;
}

.banner .subtext{
    color: rgb(43, 177, 43);
}


@media only screen and (max-width: 600px){
    
    .home .banner{
        flex-direction: column-reverse;
        font-size:1rem;
        justify-content:start;
        margin-left: 0px;
        width: 100%;
    }

    .home .banner h2{
        font-size:1rem;
        margin-bottom: 0px;
    }
    
    .home .banner h1{
        font-size:1.5rem;
    }

   

    .banner > .bannercontent{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    
    .banner .staticstics{
        gap: 1rem;
    }
        
    .banner .image{
        background: url("junkpickup.jpg")  no-repeat center;
        aspect-ratio: 16/16;
        background-size: cover;
        width: 100%;
        mix-blend-mode:multiply;
    }

    
}