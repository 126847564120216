footer{
    background-color: black;
    color: greenyellow;
    text-align:left;
    padding: 1rem;
}

footer > div{
    display: flex;
    justify-content: space-around;
}

footer ul{
    margin: 0px;
}

footer li{
   list-style: none;
  
}


footer a{
    text-decoration-line: none;
    text-decoration: none;
    color: rgb(255, 255, 255);;
}

footer a:hover{
    color: rgb(71, 104, 235);
}

.footerinfo{
    display: flex;
    width: 90%;
    justify-content:space-between;
}



@media only screen and (max-width: 600px){
    .footerinfo{
        flex-direction: column;
    }
}