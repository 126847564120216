.itemcard{
    background-color: rgb(91, 175, 112);
    width:250px;
    height: 250px;
    overflow: hidden;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.637), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 2px;
    display:flex;
    flex-direction: column;

}
.itemcard h4{
    margin:3px
}
.itemimage{
    width: 100%;
    height: 200px;
}
.itemcarddetail{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 10%;
}