.pickupestimate .itemestimatecard{
    padding-bottom: 0.5rem;
    background-color: beige;
    box-shadow:0 4px 8px 0 rgba(0, 0, 0, 0.637), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    width:300px;
    border-radius: 2px;
}
.itemestimatecard img {
    width: 100%;
    height: 200px;
    object-fit:fill
}
